























































import { Vue, Prop, PropSync, Component } from 'vue-property-decorator'
import { utils, writeFile, WorkSheet, WorkBook } from 'xlsx'
import { is } from '@/utils/helpers'
import _ from 'lodash'
import { ErrorFile, SendData, DialogStatus, BatchCreateRes, FormData } from '../types/index'

@Component
export default class BatchCreateDialog extends Vue {
  @PropSync('visible') syncVisible: boolean
  @Prop(Object) formData: FormData
  @Prop(Number) rooms: number
  private dialogStatus: DialogStatus = 'start'
  private batchCreateRes: BatchCreateRes = {
    total: 0,
    fail: 0,
    success: 0,
    failInfo: []
  }
  downloadErrorFile() {
    const errorFileData: ErrorFile = []
    for (const i in this.batchCreateRes.failInfo) {
      const { name, reason } = this.batchCreateRes.failInfo[i]
      errorFileData[i] = [name, reason]
    }
    errorFileData.unshift(['门牌号', '失败原因'])
    const worksheet: WorkSheet = utils.aoa_to_sheet(errorFileData)
    const workbook: WorkBook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, '第一页')
    writeFile(workbook, '分析结果.xlsx')
  }
  dialogClose() {
    this.syncVisible = false
    this.dialogStatus = 'start'
    this.$emit('batchCreated')
  }
  async dialogConfirm() {
    this.dialogStatus = 'loading'
    const res: AnyObj = await this.batchCreateHouse()
    if (res.data.code === 0) {
      this.batchCreateRes = res.data.data
      this.$store.dispatch('areas/fetchAreas')
      if (this.batchCreateRes?.fail) {
        this.dialogStatus = 'error'
      } else {
        this.dialogStatus = 'success'
      }
    } else {
      this.dialogClose()
    }
  }
  async batchCreateHouse() {
    const { building, unitNum, groundFloorNum, undergroundFloorNum, householdNum, areaId } =
      this.formData

    const sendData: SendData = {
      building: Number(building),
      unitNum,
      groundFloorNum,
      undergroundFloorNum,
      householdNum,
      areaId
    }
    return await this.$api.europa.batchCreateHouse(_.pickBy(sendData, is.ava))
  }
}
