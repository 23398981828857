































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import InputSearch from '@/components/common/InputSearch.vue'
import Modal from '@/components/common/Modal'
import HouseDrawer from './HouseDrawer.vue'
import BatchCreateDrawer from './BatchCreateDrawer.vue'
import { house, options } from '@/utils/options'
import { ElTable } from 'element-ui/types/table'
import { is, round } from '@/utils/helpers'
import mitt from '@/utils/mitt'
import _ from 'lodash'
import treeFind from '@/libs/operation-tree-node/treeFind'

const drawer = Modal(HouseDrawer)
const batchCreateDrawer = Modal(BatchCreateDrawer)

@Component({ components: { InputSearch, HouseDrawer } })
export default class HouseList extends Vue {
  @Prop({ default: () => ({}) }) area: AnyObj
  @Prop({ default: () => [] }) areaList: AnyObj[]

  @Ref('cards') cards?: HTMLDivElement
  @Ref('table') table?: ElTable
  @Ref('drawer') drawer?: HouseDrawer

  statistic: AnyObj = {}
  houses: AnyObj[] = []
  count = 0
  searchType: 'name' | 'landlordName' = 'name'
  pagination = { pageSize: 20, currentPage: 1 }
  filterData: AnyObj = { type: '', status: '' }
  activedTab: 'cards' | 'list' = 'cards'
  loading = false
  houseTotal = 0

  get state() {
    return this.$store.state.houses
  }

  get placeholder() {
    return this.searchType === 'name' ? '请输入门牌号查询' : '请输入户主姓名查询'
  }

  get statusOptions() {
    return house.statuses
  }

  get typeOptions() {
    return house.types
  }

  get searchTypeOptions() {
    return house.searchTypes
  }

  get total() {
    const { landlords, relatives, renters } = this.statistic

    return landlords + relatives + renters
  }

  get areas(): AnyObj[] {
    return this.$store.state.areas.areas
  }
  get rentalRate() {
    const round = (num: number, n: number) => Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
    const { rentalRate } = this.statistic

    return rentalRate ? round(rentalRate * 100, 2) : 0
  }

  get isCardsTab() {
    return this.activedTab === 'cards'
  }

  get params() {
    const { currentPage, pageSize } = this.pagination

    return {
      areaIds: [this.area.id],
      offset: (currentPage - 1) * pageSize,
      limit: pageSize,
      ...this.filterData
    }
  }

  get isBuilding(): boolean {
    // 可以批量创建的条件为本身的level=0并且level=1的父节点addressType=1
    return this.getAreaAddressType(this.area) === 1 && this.area.level === 0
  }
  getAreaAddressType(area: any): number | null {
    // 查找level=1的父节点
    while (area.level == 0) {
      area = treeFind(this.areas, ({ id }) => id === area.parentId)
    }
    return area.addressType
  }
  getHouseStatus(value: string) {
    return options(this.statusOptions).find(value) ?? {}
  }

  getHouseType(value: string) {
    return options(this.typeOptions).find(value) ?? {}
  }

  @Watch('area')
  async watchArea() {
    this.filter()
  }

  @Watch('pagination', { deep: true })
  async watchPagination() {
    await this.fetchHouses()

    if (this.table) this.table.bodyWrapper.scrollTop = 0
    if (this.cards) this.cards.scrollTop = 0
  }

  mounted() {
    mitt.on('houses:update', this.fetchHouses)
    mitt.on(['residents:change', 'houses/residents:change', 'houses/rooms:change'], this.fetch)
    drawer.on('created', this.fetch)
    drawer.on('updated', this.fetchHouses)
    batchCreateDrawer.on('batchCreated', this.fetch)
  }

  fetch() {
    this.fetchStatistic()
    this.fetchHouses()
  }

  filter() {
    this.pagination.currentPage = 1
    this.fetch()
  }

  async fetchStatistic() {
    const params = _.pickBy(_.omit(this.params, ['limit', 'offset']), is.ava)
    const { data } = await this.$api.europa.getHouseStatistic(params)

    if (data.code === 0) {
      this.statistic = data.data
    }
  }

  fetchAreas() {
    this.$store.dispatch('areas/fetchAreas')
  }

  async fetchHouses() {
    this.loading = true

    const params = _.pickBy(this.params, is.ava)
    const { data } = await this.$api.europa.getHouseLists(params)

    this.loading = false

    if (data.code === 0) {
      this.houses = data.data
      this.count = data.count
    }
  }

  rentalFilter(val: number) {
    return round(val, 0)
  }

  setParams(data?: AnyObj) {
    this.filterData = { ...this.filterData, ...data }
  }

  switchTab(tab: AnyObj) {
    this.activedTabId = tab.id
  }

  searchSelectChange(type: any) {
    this.setParams(this.searchTypeOptions.reduce((a, b) => ({ ...a, [b.value]: null }), {}))
    this.searchType = type
  }

  searchInputChange(value: any) {
    this.setParams({ [this.searchType]: value, fuzzy: value ? true : null })
    this.filter()
  }

  createHouse() {
    drawer.open({ mode: 'create', data: { areaId: this.area.id } })
  }

  batchCreateHouse() {
    batchCreateDrawer.open({ data: { areaId: this.area.id } })
  }

  updateHouse(house: AnyObj) {
    drawer.open({ mode: 'update', data: house })
  }

  viewHouse(house: AnyObj) {
    this.$router.push({ name: '房屋信息查看', params: { id: house.id } })
  }

  deleteHouse(house: AnyObj) {
    this.$confirm('房屋一经删除无法恢复，房屋上的全部数据也将删除，是否确定删除?', '提示', {
      type: 'warning',
      callback: async action => {
        if (action === 'confirm') {
          const { data } = await this.$api.europa.deleteHouse(house.id)

          if (!data.error) {
            this.$message({ message: '删除成功', type: 'success' })
            this.fetch()
            this.fetchAreas()
          }
        }
      }
    })
  }
}
