



































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import BatchCreateDialog from './components/BatchCreateDialog.vue'
import { themeName } from '@/libs/theme/index'
import { ElForm } from 'element-ui/types/form'
import treeFind from '@/libs/operation-tree-node/treeFind'
import { FormData } from './types/index'

@Component({ components: { FormDrawer, BatchCreateDialog } })
export default class BatchCreateDrawer extends Vue {
  @Prop({ default: () => ({}) }) data: AnyObj
  @Prop({ default: false }) visible: boolean

  @Ref() private form: ElForm

  private batchCreateDialogVisible = false
  private formData: FormData = this.defaultFormData
  private rules = {
    areaId: [{ required: true, message: '请选择房屋地址', trigger: 'change' }],
    building: [
      { required: true, message: '请输入楼栋号', trigger: 'blur' },
      { type: 'number', message: '楼栋号必须大于0', min: 1 }
    ],
    unitNum: [
      {
        validator: this.validate('单元数量最小为0'),
        trigger: 'change'
      }
    ],
    groundFloorNum: [
      {
        validator: this.validate('地上楼层数量最小为0'),
        trigger: 'change'
      }
    ],
    undergroundFloorNum: [
      {
        validator: this.validate('地下楼层数量最小为0'),
        trigger: 'change'
      }
    ],
    householdNum: [{ required: true, message: '请输入户室数量', trigger: 'change' }]
  }

  private loading = false
  get rooms() {
    // 当楼层和单元都为0时，返回值为户室数量householdNum
    const { unitNum, groundFloorNum, undergroundFloorNum, householdNum } = this.formData
    const arr = [unitNum, groundFloorNum + undergroundFloorNum, householdNum]
    let res = 1
    arr.filter(item => item !== 0).forEach(item => (res *= item))
    return res
  }
  get title() {
    return '批量创建房屋'
  }

  get defaultFormData() {
    return {
      areaId: '',
      building: '',
      unitNum: 1,
      groundFloorNum: 1,
      undergroundFloorNum: 0,
      householdNum: 3,
      areaName: ''
    }
  }

  get areaList(): AnyObj[] {
    return this.$store.state.areas.areas
  }

  get batchLeftBG() {
    if (themeName === 'dark') {
      return `url(${require('@/assets/images/house/batch-create-left-dark.png')})`
    }
    return `url(${require('@/assets/images/house/batch-create-left-light.png')})`
  }
  get batchCenterBG() {
    if (themeName === 'dark') {
      return `url(${require('@/assets/images/house/batch-create-center-dark.png')})`
    }
    return `url(${require('@/assets/images/house/batch-create-center-light.png')})`
  }
  get areaName() {
    return this.areaFind(this.data.areaId)?.name
  }

  mounted() {
    this.initialize()
  }

  async initialize() {
    const { areaId } = this.data
    const { unitNum, groundFloorNum, undergroundFloorNum, householdNum } = this.defaultFormData
    this.formData = {
      ...this.formData,
      unitNum,
      groundFloorNum,
      undergroundFloorNum,
      householdNum,
      areaId,
      areaName: this.areaName
    }
  }
  validate(msg: string) {
    return (r: any, value: any, cb: any) => {
      if (value == null) {
        cb(new Error(msg))
      } else {
        cb()
      }
    }
  }
  areaFind(areaId: string) {
    return treeFind(this.areaList, ({ id }) => id === areaId)
  }

  private batchCreated() {
    this.close()
    this.$emit('batchCreated')
  }
  private submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.batchCreateDialogVisible = true
      }
    })
  }

  private close() {
    this.form.resetFields()
    this.formData = this.defaultFormData
    this.address = ''
    this.visible = false
  }
}
