
















import { Vue, Component } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import Areas from '@/components/common/Areas/index.vue'
import HouseList from './HouseList/index.vue'

@Component({ components: { PageTitle, Areas, HouseList } })
export default class HouseManagement extends Vue {
  currentArea: AnyObj = {}

  get isChildRoute() {
    return this.$route.name === '房屋信息查看'
  }

  areaChange(area: AnyObj) {
    this.currentArea = area
  }
}
